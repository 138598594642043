<template>
  <div
    class="item_wrapper"
    :class="{ 'hover' : isHover }"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
    @click="$router.push({ name: 'News.Article', params: { uuid: item.uuid } })"
  >

    <!-- 左：圖片 -->
    <div class="item_left" :style="`background-image: url(${item.pic})`">
      <div class="item_left_cover"></div>
      <LinkMore
        class="txt-white item_left_cover_link"
        linkText="SEE MORE"
        fillColor="#fff"/>
    </div>

    <!-- 右：內容 -->
    <div class="item_right">
      <small class="txt-main">{{item.created}}</small>
      <p
        class="item_title large bold txt-black"
        :class="{ 'prefix-redCube' : isHover && !isMobile }"
      >
        {{item.title}}
      </p>
      <p
        class="txt-dark_gray-1 ellipsis"
        v-html="item.description"
      ></p>
    </div>
  </div>
</template>

<script>
import LinkMore from '@/components/LinkMore.vue';

export default {
  name: 'NewsItem',
  props: ['item'],
  components: {
    LinkMore,
  },
  data() {
    return {
      isHover: false,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = window.screen.width <= 768;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.item_wrapper {
  display: flex;
  margin-bottom: 2.5%;
  height: 250px;
  cursor: pointer;
  overflow: hidden;

  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item_left {
    position: relative;
    width: 30%;
    min-width: 420px;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .item_left_cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: 0;
    }

    .item_left_cover_link {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  .item_right {
    padding: 2.5% 7% 3% 3.8%;
    width: 70%;
    background-color: #fff;
  }

  .item_title {
    padding: 2.5% 0;
    transition: transform 0.1s linear;
  }

  @media screen and (min-width: 1024px) {
    &.hover {
      box-shadow: 10px 10px 20px #0000000D;
      .item_left_cover {
        opacity: .7;
      }
      .item_left_cover_link {
        opacity: 1;
      }
    }
    &:hover {
      .item_title {
        transform: translateX(5%);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    height: 200px;
    .item_left {
      min-width: 355px;
    }

    .ellipsis {
      -webkit-line-clamp: 2;
    }
  }

  @media screen and (max-width: 768px) {
    height: 150px;
    .item_left {
      min-width: 260px;
    }
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
    padding: 1.5rem 0;
    height: 500px;

    .item_left, .item_right {
      width: 100%;
    }

    .item_left {
      height: 345px;
    }
    .item_right {
      padding: 1.25rem 1.875rem;
    }
  }

  @media screen and (max-width: 540px) {
    height: 400px;
    .item_left {
      height: 400px;
    }
  }

  @media screen and (max-width: 325px) {
    height: 350px;
    .item_left {
      height: 350px;
    }
  }
}
</style>
