<template>
  <div class="news">
    <div class="bg_decor"></div>
    <Banner
      title="LATEST NEWS"
      subtitle="最新消息"
      :img="bannerImg"
    />
    <div class="container w1400">
      <List :list="newsArticles" />
      <Pagination
        :page="currentPage"
        :totalPage="totalPage"
        @updatePage="updatePage"
      />
    </div>
  </div>
</template>

<script>
import bannerImg from '@/assets/imgs/banner/banner_news.png';
import Banner from '@/components/banner/Index.vue';
import List from '@/components/news/List.vue';
import Pagination from '@/components/pagination/Index.vue';

import requestApi from '@/lib/http';
import { formatNews } from '@/utils/formatResponse';
import { isEqual } from '@/lib/lodash';

export default {
  name: 'NewsIndex',
  components: {
    Banner,
    List,
    Pagination,
  },
  data() {
    return {
      bannerImg,
      newsArticles: [],
      totalPage: 0,
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route?.query?.page, 10) || 1;
    },
  },
  methods: {
    async getData(page = 1) {
      const perPage = 6;
      const skip = (page - 1) * perPage;

      const { entries: data, total } = await requestApi('common.getNews', {
        limit: perPage, // 每頁幾筆資料
        skip, // 跳過幾筆資料後開始顯示
      });
      this.newsArticles = data.map(formatNews).reverse();
      this.totalPage = Math.ceil(total / perPage);
    },
    updatePage(page) {
      if (!isEqual(Number(this.$route.query.page, 10), Number(page, 10))) {
        this.$router.push({ query: { page } });
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          const { page } = newVal.query;
          this.getData(Number(page, 10));
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.news {
  position: relative;
  // background: no-repeat;
  // background-image: url('../../assets/imgs/news/bg_2.svg');
  // background-position: 100% 100%;
  // background-attachment: fixed;
  background-color: $color-light_gray;
  .bg_decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat;
    background-image: url('../../assets/imgs/news/bg_list.png');
    background-size: 50%;
    background-position: 100% 100%;
    background-attachment: fixed;
    // background-color: $color-light_gray;
  }
  .container {
    position: relative;
    padding: 6% 0;
  }
}
</style>
