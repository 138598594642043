<template>
  <div>
    <div v-for="(item, k) in list" :key="`item_${k}`">
      <Item :item="item"/>
    </div>
  </div>
</template>

<script>
import Item from '@/components/news/Item.vue';

export default {
  name: 'NewsList',
  props: ['list'],
  components: {
    Item,
  },
};
</script>
